import {
  Card,
  CardContent,
  CardMedia,
  Grid,
  IconButton,
  Typography,
  makeStyles,
} from "@material-ui/core";
import { Divider } from "antd";
import React from "react";
import PlayArrowIcon from "@material-ui/icons/PlayArrow";
import ClosedCaptionIcon from '@material-ui/icons/ClosedCaption';

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    minHeight: 200,
    width: "80%",
    boxShadow: "2px 2px 10px rgba(0,0,0,0.4)"
  },
  details: {
    display: "flex",
    flexDirection: "column",
    flexBasis: "60%",
  },
  content: {
    flex: "1 0 auto",
  },
  cover: {
    width: "100%",
    objectFit: 'cover'
  },
  controls: {
    display: "flex",
    alignItems: "center",
    paddingLeft: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    justifyContent: "space-between"
  },
  playIcon: {
    height: 38,
    width: 38,
  },
}));

export default function CollegeMap() {
  const classes = useStyles();
  return (
    <div style={{ padding: 10 }}>
      <Grid container={2}>
        <Grid item xs={12} sm={12} lg={3} md={3}>
          <Divider
            orientation="center"
            style={{ fontWeight: 700, fontSize: 20, borderColor: "#ABB2B9" }}
          >
            The Emblem
          </Divider>
          <div style={{ textAlign: "center" }}>
            <img
              src="/images/prag-logo-small.png"
              style={{ width: 120, marginBottom: 20 }}
            />
          </div>

          <Typography
            variant="body1"
            gutterBottom
            style={{ textAlign: "justify", fontWeight: 600, fontSize: 14 }}
          >
            The conch on the eight-petaloid full-bloomed graceful lotus
            symbolises pure knowledge.तेजस्विनावधीतमस्तु
            (Tejasvinavadhitamastu), the inspiring Motto of our college, speaks:
            May our study make us illumined. The aims, objectives and ideals of
            education are epitomised in this great saying of Taittiriya
            Upanishad (Ch. II Brahmananda Valli, Section 1, Invocation).
          </Typography>

      
          

         
        </Grid>
        <Grid item xs={12} sm={12} lg={6} md={6}>
        <div style={{width: "100%", display: "flex", justifyContent: "center", alignItems: "center"}}>
        <Card className={classes.root}>
            <div className={classes.details}>
              <CardContent className={classes.content}>
                
                <Typography component="h5" variant="h5" style={{fontWeight: 700}}>
                  College Anthem
                </Typography>
                <Typography variant="subtitle1" color="textSecondary" style={{fontWeight: 700}}>
                  Pragjyotish College
                </Typography>
                <div style={{textAlign: "center", marginTop: 20}}>
                <img src="/images/pc_anthem_logo.png" className="img-spin" style={{width: "60%"}} />
                </div>
              </CardContent>
              <div className={classes.controls}>
                <IconButton
                  aria-label="play/pause"
                  target="_blank"
                  href="/images/pc_anthem.mp3"
                >
                  <PlayArrowIcon className={classes.playIcon} />
                </IconButton>

                <IconButton
                  aria-label="play/pause"
                  target="_blank"
                  href="/images/pc_anthem_main.jpg"
                >
                  <ClosedCaptionIcon className={classes.playIcon} />
                </IconButton>
              </div>
            </div>
            <CardMedia
              className={classes.cover}
              image="/images/pc_anthem_main.jpg"
              title="College Anthem"
            />
          </Card>
        </div>
        </Grid>
        <Grid item xs={12} sm={12} lg={3} md={3}>
          <div style={{ textAlign: "center" }}>
            <img
              src="/images/pc_map.jpeg"
              style={{ width: "100%", marginBottom: 20, height: 350, objectFit: "contain" }}
            />
          </div>
        </Grid>
      </Grid>
    </div>
  );
}
