

export const BaseURL = process.env.NODE_ENV === 'production' ? 'https://webbackend.pragjyotishcollege.ac.in/public' : 'http://localhost/prag-website-new/api/public';
export const WebURL = 'https://backend.pragjyotishcollege.ac.in/public';

export const SERVER_UPLOAD = process.env.NODE_ENV === 'production' ? 'https://webbackend.pragjyotishcollege.ac.in/src/uploads' : 'http://localhost/prag-website-new/api/src/uploads';
export const SERVER_UPLOAD_NEW = process.env.NODE_ENV === 'production' ? 'https://backend.pragjyotishcollege.ac.in/src/uploads' : 'http://localhost/prag-website-new/api/src/uploads';

export function PostData(type, userData) {

    return new Promise((resolve, reject) => {
        fetch(BaseURL + type, {
            method: 'POST',
            body: JSON.stringify(userData),
            headers: {
                'Content-Type': 'application/json',
                'Unit': 'website'
            }
        })
            .then((resp) => {
                if (!resp.ok) {
                    throw resp.text();
                } else {
                    return resp.json();
                }
            })
            .then((res) => {
                resolve(res);
            })
            .catch((error) => {
                console.log(error)
                reject(error);
            });
    });
}





export function PostDataExtra(type, userData) {

    return new Promise((resolve, reject) => {
        fetch(WebURL + type, {
            method: 'POST',
            body: JSON.stringify(userData),
            headers: {
                'Content-Type': 'application/json',
                'Unit': 'website'
            }
        })
            .then((resp) => {
                if (!resp.ok) {
                    throw resp.text();
                } else {
                    return resp.json();
                }
            })
            .then((res) => {
                resolve(res);
            })
            .catch((error) => {
                console.log(error)
                reject(error);
            });
    });
}

export function GetData(type) {


    return new Promise((resolve, reject) => {
        fetch(BaseURL + type, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Unit': 'website'
            }
        })
            .then((resp) => {
                if (!resp.ok) {

                    throw resp.text();

                } else {
                    return resp.json();
                }
            })
            .then((res) => {
                resolve(res);
            })
            .catch((error) => {
                console.log(error)
                reject(error);
            });
    });
}


