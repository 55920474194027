import React from 'react'
import AliceCarousel from "react-alice-carousel";
import "react-alice-carousel/lib/alice-carousel.css";
import { CardContent, Container, Grid, Typography } from "@material-ui/core";
import { Divider, Card } from "antd";
import { GetData, PostData } from "../../api/service";


const handleDragStart = (e) => e.preventDefault();


export default function FacultyAchivement() {
    const [data, setData] = React.useState([]);
    const [isDataLoaded, setIsDataLoaded] = React.useState(false);

    function getDepartment() {
    
        PostData(`/getnoticesbytypedeptv1`, {
          dept_code: "PRAG",
          type: "COLLEGE_EMP_ACHIEVEMENTS",
          year: "",
        }).then((resp) => {
          setData(resp);
          setIsDataLoaded(true);
        });
      }
    
      React.useEffect(() => {
        getDepartment();
      }, []);
  return (
    <div><Divider
    orientation="center"
    style={{ fontWeight: 700, fontSize: 20, borderColor: "#ABB2B9" }}
  >
    Faculty Achievements
  </Divider>
  <AliceCarousel
    responsive={{
      0: {
        items: 1,
      },
      1024: {
        items: 1,
        itemsFit: "contain",
      },
    }}
    autoPlay={true}
    autoWidth={true}
    autoHeight={true}
    autoPlayInterval={2000}
    dotsDisabled={true}
    mouseTracking
    items={data.map((el, index) => {
      return (
        <div>
          {Array.isArray(el.upload_info) && el.upload_info.length > 0 && <img
            src={el.upload_info[0].url}
            onDragStart={handleDragStart}
            role="presentation"
            style={{
              width: "100%",
              height: "400px",
              objectFit: "contain",
            }}
          />}
          <br />
          <br />
          <Typography
            style={{
              fontWeight: 700,
              fontSize: 14,
              marginTop: 20,
              textAlign: "center",
            }}
          >
            {el.title}
          </Typography>
        </div>
      );
    })}
  /></div>
  )
}
