import React, { Component } from 'react'
import { PostData } from '../../api/service';
import {Divider} from 'antd'
import {Container, Typography} from '@material-ui/core'

import AliceCarousel from "react-alice-carousel";
import "react-alice-carousel/lib/alice-carousel.css";

const handleDragStart = (e) => e.preventDefault();


export default class StudentPlacement extends Component {
    state = {
        loadedData: [],
        data: [],
        isDataLoaded: false,
    
        isLoaded: false,
      };
    
      componentDidMount() {
        PostData(`/getphotosforwebsitewithcaption`, {
          type: "HIGHLIGHTS",
        }).then((resp) => {
          this.setState({
            loadedData: resp,
            isLoaded: true,
          });
        });
    
       
      }
  render() {
    return (
      <div> <Divider
      orientation="center"
      style={{
        fontWeight: 700,
        fontSize: 20,
        borderColor: "#ABB2B9",
      }}
    >
      Student Acheivements
    </Divider>
    <Container>
      <AliceCarousel
        responsive={{
          0: {
            items: 1,
          },
          1024: {
            items: 1,
            itemsFit: "contain",
          },
        }}
        autoPlay={true}
        autoWidth={true}
        autoHeight={true}
        autoPlayInterval={2000}
        dotsDisabled={true}
        mouseTracking
        items={this.state.loadedData
          .filter((el) => el.type == "HIGHLIGHTS")
          .map((el, index) => {
            return (
              <div>
                <img
                  src={el.url}
                  onDragStart={handleDragStart}
                  role="presentation"
                  style={{
                    width: "100%",
                    height: "400px",
                    objectFit: "contain",
                  }}
                />
                <br />
                <br />
                <Typography
                  style={{
                    fontWeight: 700,
                    fontSize: 14,
                    marginTop: 20,
                    textAlign: "center",
                  }}
                >
                  {el.caption}
                </Typography>
              </div>
            );
          })}
      />
    </Container></div>
    )
  }
}
