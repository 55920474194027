import { CardContent, Container, Grid, Typography } from "@material-ui/core";
import { Divider, Card } from "antd";
import React from "react";
import { GetData, PostData } from "../../api/service";
import history from "../../history";
import AliceCarousel from "react-alice-carousel";
import "react-alice-carousel/lib/alice-carousel.css";
import { RightCircleOutlined } from "@ant-design/icons";
import FacultyAchivement from "./FacultyAchivement";
import StudentPlacement from "../Highlights/StudentPlacement";
import Testimonials from "../Testimonials";
const handleDragStart = (e) => e.preventDefault();
const SECTIONS_LIST = [
  {
    title: "Faculty of Arts",
    value: "ARTS",
  },
  {
    title: "Faculty of Science",
    value: "SCIENCE",
  }
 
];

const SECTIONS_LIST1 = [
  
  {
    title: "Faculty of Commerce",
    value: "COMMERCE",
  },
  {
    title: "Faculty of Management",
    value: "OTHERS",
  },
];

export default function KnowYourFaculty() {
  const [dept, setDept] = React.useState([]);
  
  const [isLoaded, setIsLoaded] = React.useState(false);


  function getDepartment() {
    GetData(`/getdepartments`).then((resp) => {
      //let data = resp.filter((el)=>el.stream==this.state.stream)
      // console.log("🚀 ~ file: KnowYourFaculty.js:20 ~ GetData ~ resp:", resp);
      setDept(resp);
      setIsLoaded(true);
    });

   
  }

  React.useEffect(() => {
    getDepartment();
  }, []);

  const [showAllArts, setShowAllArts] = React.useState(false);
  const [showAllScience, setShowAllScience] = React.useState(false);

  return (
    <div>
      <div style={{ padding: 10 }}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={12} md={4} lg={4}>
            <Divider
              orientation="center"
              style={{ fontWeight: 700, fontSize: 20, borderColor: "#ABB2B9" }}
            >
              Academic Faculty
            </Divider>

            <Grid container spacing={2}>
              {SECTIONS_LIST.map((el1, index1) => (
                <Grid
                  item
                  key={index1}
                  xs={12}
                  sm={12}
                  md={6}
                  lg={6}
                  style={{
                    cursor: "pointer",
                  }}
                >
                  <Card
                    border="primary"
                    style={{ borderTop: "solid 4px #4F58D2" }}
                  >
                    <Typography variant="h6">{el1.title}</Typography>
                    <Typography variant="caption" gutterBottom>
                      Click the Department Names to visit the departmental pages
                    </Typography>

                    <br />

                    <ul style={{ listStyleType: "none", marginLeft: -30 }}>
                      {el1.value == "ARTS" &&
                        dept
                          .filter(
                            (el) => el.stream == el1.value || el.is_dual == true
                          )
                          .map((el, index) => {
                            if (showAllArts == true) {
                              return (
                                <li>
                                  <a
                                    onClick={() =>
                                      history.push(
                                        `/department/${el.dept_code}`
                                      )
                                    }
                                    style={{ color: "blue", fontSize: 16 }}
                                  >
                                    <div
                                      style={{
                                        display: "flex",
                                        alignItems: "center",
                                      }}
                                    >
                                      <RightCircleOutlined /> &nbsp;&nbsp;
                                      {el.dept_name.toUpperCase()}
                                    </div>
                                  </a>
                                </li>
                              );
                            } else {
                              if (index <= 3) {
                                return (
                                  <li>
                                    <a
                                      onClick={() =>
                                        history.push(
                                          `/department/${el.dept_code}`
                                        )
                                      }
                                      style={{ color: "blue", fontSize: 16 }}
                                    >
                                      <div
                                        style={{
                                          display: "flex",
                                          alignItems: "center",
                                        }}
                                      >
                                        <RightCircleOutlined /> &nbsp;&nbsp;
                                        {el.dept_name.toUpperCase()}
                                      </div>
                                    </a>
                                  </li>
                                );
                              }
                            }
                          })}

                      {el1.value == "SCIENCE" &&
                        dept
                          .filter(
                            (el) => el.stream == el1.value || el.is_dual == true
                          )
                          .map((el, index) => {
                            if (showAllScience == true) {
                              return (
                                <li>
                                  <a
                                    onClick={() =>
                                      history.push(
                                        `/department/${el.dept_code}`
                                      )
                                    }
                                    style={{ color: "blue", fontSize: 16 }}
                                  >
                                    <div
                                      style={{
                                        display: "flex",
                                        alignItems: "center",
                                      }}
                                    >
                                      <RightCircleOutlined /> &nbsp;&nbsp;
                                      {el.dept_name.toUpperCase()}
                                    </div>
                                  </a>
                                </li>
                              );
                            } else {
                              if (index <= 3) {
                                return (
                                  <li>
                                    <a
                                      onClick={() =>
                                        history.push(
                                          `/department/${el.dept_code}`
                                        )
                                      }
                                      style={{ color: "blue", fontSize: 16 }}
                                    >
                                      <div
                                        style={{
                                          display: "flex",
                                          alignItems: "center",
                                        }}
                                      >
                                        <RightCircleOutlined /> &nbsp;&nbsp;
                                        {el.dept_name.toUpperCase()}
                                      </div>
                                    </a>
                                  </li>
                                );
                              }
                            }
                          })}

                      {el1.value == "ARTS" && (
                        <div style={{ textAlign: "right" }}>
                          <a
                            onClick={() => setShowAllArts(!showAllArts)}
                            style={{ color: "blue" }}
                          >
                            <u>{!showAllArts ? "Show More" : "Show Less"}</u>
                          </a>
                        </div>
                      )}

                      {el1.value == "SCIENCE" && (
                        <div style={{ textAlign: "right" }}>
                          <a
                            onClick={() => setShowAllScience(!showAllScience)}
                            style={{ color: "blue" }}
                          >
                            <u>{!showAllScience ? "Show More" : "Show Less"}</u>
                          </a>
                        </div>
                      )}

                      {(el1.value == "COMMERCE" || el1.value == "OTHERS") &&
                        dept
                          .filter((el) => el.stream == el1.value)
                          .map((el, index) => (
                            <li>
                              <a
                                onClick={() =>
                                  history.push(`/department/${el.dept_code}`)
                                }
                                style={{ color: "blue", fontSize: 16 }}
                              >
                                <div
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                  }}
                                >
                                  <RightCircleOutlined />
                                  &nbsp;&nbsp;
                                  {el.dept_name.toUpperCase()}
                                </div>
                              </a>
                            </li>
                          ))}
                    </ul>
                  </Card>
                </Grid>
              ))}
            </Grid>
            <Grid container spacing={2}>
              {SECTIONS_LIST1.map((el1, index1) => (
                <Grid
                  item
                  key={index1}
                  xs={12}
                  sm={12}
                  md={6}
                  lg={6}
                  style={{
                    cursor: "pointer",
                  }}
                >
                  <Card
                    border="primary"
                    style={{ borderTop: "solid 4px #4F58D2" }}
                  >
                    <Typography variant="h6">{el1.title}</Typography>
                    <Typography variant="caption" gutterBottom>
                      Click the Department Names to visit the departmental pages
                    </Typography>

                    <br />

                    <ul style={{ listStyleType: "none", marginLeft: -30 }}>
                      {el1.value == "ARTS" &&
                        dept
                          .filter(
                            (el) => el.stream == el1.value || el.is_dual == true
                          )
                          .map((el, index) => {
                            if (showAllArts == true) {
                              return (
                                <li >
                                  <a
                                    onClick={() =>
                                      history.push(
                                        `/department/${el.dept_code}`
                                      )
                                    }
                                    style={{ color: "blue", fontSize: 16 }}
                                  >
                                    <div
                                      style={{
                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: "flex-start"
                                      }}
                                    >
                                      <RightCircleOutlined /> &nbsp;&nbsp;
                                      {el.dept_name.toUpperCase()}
                                    </div>
                                  </a>
                                </li>
                              );
                            } else {
                              if (index <= 3) {
                                return (
                                  <li>
                                    <a
                                      onClick={() =>
                                        history.push(
                                          `/department/${el.dept_code}`
                                        )
                                      }
                                      style={{ color: "blue", fontSize: 16 }}
                                    >
                                      <div
                                        style={{
                                          display: "flex",
                                          alignItems: "center",
                                        }}
                                      >
                                        <RightCircleOutlined /> &nbsp;&nbsp;
                                        {el.dept_name.toUpperCase()}
                                      </div>
                                    </a>
                                  </li>
                                );
                              }
                            }
                          })}

                      {el1.value == "SCIENCE" &&
                        dept
                          .filter(
                            (el) => el.stream == el1.value || el.is_dual == true
                          )
                          .map((el, index) => {
                            if (showAllScience == true) {
                              return (
                                <li>
                                  <a
                                    onClick={() =>
                                      history.push(
                                        `/department/${el.dept_code}`
                                      )
                                    }
                                    style={{ color: "blue", fontSize: 16 }}
                                  >
                                    <div
                                      style={{
                                        display: "flex",
                                        alignItems: "center",
                                      }}
                                    >
                                      <RightCircleOutlined /> &nbsp;&nbsp;
                                      {el.dept_name.toUpperCase()}
                                    </div>
                                  </a>
                                </li>
                              );
                            } else {
                              if (index <= 3) {
                                return (
                                  <li>
                                    <a
                                      onClick={() =>
                                        history.push(
                                          `/department/${el.dept_code}`
                                        )
                                      }
                                      style={{ color: "blue", fontSize: 16 }}
                                    >
                                      <div
                                        style={{
                                          display: "flex",
                                          alignItems: "center",
                                        }}
                                      >
                                        <RightCircleOutlined /> &nbsp;&nbsp;
                                        {el.dept_name.toUpperCase()}
                                      </div>
                                    </a>
                                  </li>
                                );
                              }
                            }
                          })}

                      {el1.value == "ARTS" && (
                        <div style={{ textAlign: "right" }}>
                          <a
                            onClick={() => setShowAllArts(!showAllArts)}
                            style={{ color: "blue" }}
                          >
                            <u>{!showAllArts ? "Show More" : "Show Less"}</u>
                          </a>
                        </div>
                      )}

                      {el1.value == "SCIENCE" && (
                        <div style={{ textAlign: "right" }}>
                          <a
                            onClick={() => setShowAllScience(!showAllScience)}
                            style={{ color: "blue" }}
                          >
                            <u>{!showAllScience ? "Show More" : "Show Less"}</u>
                          </a>
                        </div>
                      )}

                      {(el1.value == "COMMERCE" || el1.value == "OTHERS") &&
                        dept
                          .filter((el) => el.stream == el1.value)
                          .map((el, index) => (
                            <li>
                              <a
                                onClick={() =>
                                  history.push(`/department/${el.dept_code}`)
                                }
                                style={{ color: "blue", fontSize: 16 }}
                              >
                                <div
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                  }}
                                >
                                  <RightCircleOutlined />
                                  &nbsp;&nbsp;
                                  {el.dept_name.toUpperCase()}
                                </div>
                              </a>
                            </li>
                          ))}
                    </ul>
                  </Card>
                </Grid>
              ))}
            </Grid>
          </Grid>

          <Grid item xs={12} sm={12} md={4} lg={4}>
          <StudentPlacement />
           
          </Grid>

          <Grid item xs={12} sm={12} md={4} lg={4}>
          <Divider
      orientation="center"
      style={{
        fontWeight: 700,
        fontSize: 20,
        borderColor: "#ABB2B9",
      }}
    >
      &nbsp;
    </Divider>
          <Testimonials type="TESTIMONIALS" showCaption={false} />
           
          </Grid>

          
        </Grid>
      </div>
    </div>
  );
}
