import React from 'react'
import { Container, Grid, Typography } from '@material-ui/core'
import { Divider, Button } from 'antd'
import { PostData } from '../../api/service'
import moment from 'moment'
import { Link } from 'react-router-dom'

import AliceCarousel from 'react-alice-carousel';
import 'react-alice-carousel/lib/alice-carousel.css';

const handleDragStart = (e) => e.preventDefault();
const items = [
  <img src="/images/iso-pc.png" onDragStart={handleDragStart} role="presentation" style={{width: "100%"}} />,
  <img src="/images/sdg-pc.png" onDragStart={handleDragStart} role="presentation" style={{width: "100%"}} />,
  <img src="/images/acc-pc.png" onDragStart={handleDragStart} role="presentation" style={{width: "100%"}} />,
];

export default function HomePageCertificates({viewTitle = true}) {

  const [activeIndex, setActiveIndex] = React.useState(0)
  
  return (
    <div>
      <Container>
        {viewTitle && <Divider orientation="left" style={{ fontWeight: 700, fontSize: 20, borderColor: "#ABB2B9" }}>CERTIFICATES</Divider>}
        <AliceCarousel autoPlay={true} autoPlayInterval={2000} disableDotsControls={true} disableButtonsControls={true} dotsDisabled={true}  mouseTracking items={items} />
        {/* <Grid container spacing={2}>
          <Grid item xs={12} sm={12} md={12} lg={12}>

            <img src={`/images/sdg-pc.png`} style={{width: '100%'}} />
          </Grid>
        


        </Grid> */}
      </Container>
    </div>
  )
}
